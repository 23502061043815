import './App.css';
import { useEffect, useState } from 'react';

const BLOCK_GAP = 10 * 60
const LCD_URL = 'https://lcd.luncblaze.com'
const dtOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hourCycle: 'h23',
  timeZoneName: 'short',
}

function App() {
  const [latestBlock, setLatestBlock] = useState(0)
  const [latestBlockTs, setLatestBlockTs] = useState(0)
  const [blockTime, setBlockTime] = useState(0)
  const [inpValue, setInpValue] = useState(0);

  function updateData() {
    try {
      fetch(`${LCD_URL}/cosmos/base/tendermint/v1beta1/blocks/latest`)
        .then(async (response) => {
          if (response.ok) {
            const pdata = await response.json()
            const latestBlk = parseInt(pdata.block.header.height)
            const latestBlkTs = (new Date(pdata.block.header.time)).getTime()
            setLatestBlock(latestBlk)
            setInpValue(latestBlk)
            setLatestBlockTs(latestBlkTs)

            fetch(`${LCD_URL}/cosmos/base/tendermint/v1beta1/blocks/${latestBlk - BLOCK_GAP}`)
              .then(async (response) => {
                if (response.ok) {
                  const pgdata = await response.json()
                  const oldTs = (new Date(pgdata.block.header.time)).getTime()
                  setBlockTime((latestBlkTs - oldTs) / BLOCK_GAP / 1e3)
                }
              })
              .catch((error) => { console.error(error) });
                }
              })
        .catch((error) => { console.error(error) });
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    updateData()
  }, [])

  const dt = new Date(latestBlockTs + ((inpValue - latestBlock) * blockTime * 1000.0))

  return (
    <div>
      <h3>Rebel Testnet Block Time Prediction</h3>
      <table>
        <tbody>
          <tr>
            <td>Latest Block</td>
            <td>{latestBlock > 0? latestBlock: ''}</td>
          </tr>
          <tr>
            <td>Latest Block Timestamp</td>
            <td>{latestBlockTs > 0? new Intl.DateTimeFormat('en-US', { ...dtOptions, timeZone: 'UTC' }).format(latestBlockTs): ''}</td>
          </tr>
          <tr>
            <td>Block Time</td>
            <td>{blockTime > 0? blockTime.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 3 }): ''}</td>
          </tr>
          <tr>
            <td>Target Block</td>
            <td>
              <input
              type='number'
              step={1}
              value={inpValue}
              onChange={(e) => {
                const val = e.target.value;
                if(val % 1 === 0) {
                  setInpValue(val);
                }
              }}
              min={latestBlock}
              />
            </td>
          </tr>
          <tr>
            <td>Target Block Timestamp UTC</td>
            <td>
              {inpValue > 0 && inpValue >= latestBlock && blockTime > 0? new Intl.DateTimeFormat('en-US', { ...dtOptions, timeZone: 'UTC' }).format(dt): ''}
            </td>
          </tr>
          <tr>
            <td>Target Block Timestamp Local</td>
            <td>
            {inpValue > 0 && inpValue >= latestBlock && blockTime > 0? new Intl.DateTimeFormat('en-US', dtOptions).format(dt): ''}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default App;
